import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import InitialsAvatar from 'react-initials-avatar';
//import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { selectProfile } from 'selectors';
import './Avatar.scss';

const ProfileDropdown = () => {
    // Inside your component
    const profile = useSelector(selectProfile)


    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <span className="text-start ms-xl-2">
                            {profile.user ? <InitialsAvatar
                                className='initials-avatar'
                                name={`${profile.user.first_name} ${profile.user.last_name}`}
                            /> : <></>}
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{profile.first_name}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"></span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Bienvenido {profile?.user?.first_name} {profile?.user?.last_name}!</h6>

                    <DropdownItem className='p-0'>
                        <Link to="/settings" className="dropdown-item">
                            <i className="mdi mdi-cog text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle" data-key="t-settings">Configuración</span>
                        </Link>
                    </DropdownItem>

                    <DropdownItem className='p-0'>
                        <Link to="/logout" className="dropdown-item">
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">Salir</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;