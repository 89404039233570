
interface ReviewGridItemPreviewProp {
    title: string
    price: string
    styles: any
}

const ReviewGridItemPreview = (props: ReviewGridItemPreviewProp) => {
    return <div
        style={{
            width: 'fit-content',
        }}
        className="grid__item grid-product small--one-half medium-up--one-quarter aos-init aos-animate whatacart-processed"
        data-aos="row-of-"
        data-product-handle="vitalite"
        data-product-id="8816813342950">
        <style>{`
        .whata-star-filled,
        .whata-star-partial {
            color: ${props.styles.starColor};
        }
        .whata-star,
        .whata-star-filled,
        .whata-star-partial {
            font-size: ${props.styles.fontSize + 'rem !important' ?? ''};
        }
`}</style>
        <div className="grid-product__content"><a href="/collections/new-in/products/vitalite" className="grid-product__link">
            <div className="grid-product__image-mask"><div className="image-wrap">
                <img style={{ borderRadius: '10px' }} className="grid-product__image lazyautosizes lazyloaded" data-widths="[180, 360, 540, 720, 900, 1080]" data-aspectratio="0.7643790849673202" data-sizes="auto" alt="" data-srcset="//theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_180x.jpg?v=1724696631 180w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_360x.jpg?v=1724696631 360w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_540x.jpg?v=1724696631 540w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_720x.jpg?v=1724696631 720w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_900x.jpg?v=1724696631 900w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_1080x.jpg?v=1724696631 1080w" sizes="204px" srcSet="//theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_180x.jpg?v=1724696631 180w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_360x.jpg?v=1724696631 360w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_540x.jpg?v=1724696631 540w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_720x.jpg?v=1724696631 720w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_900x.jpg?v=1724696631 900w, //theglowfactor.com/cdn/shop/files/Vitalite_936e9606-2f10-47ee-8a52-001ed133bc65_1080x.jpg?v=1724696631 1080w" />
            </div>
            </div>
            <div className="grid-product__meta">
                <div className="grid-product__title">{props.title}</div>
                <div
                    id="whata-star-8816813342950"
                    className="whata-star-container"
                    style={{
                        textAlign: props.styles.align,
                        marginTop: props.styles.marginTop + 'rem',
                        marginBottom: props.styles.marginBottom + 'rem',
                        fontSize: props.styles.fontSize + 'rem' ?? '',
                    }}>
                    <span className="whata-star whata-star-filled">★</span><span className="whata-star whata-star-filled">★</span><span className="whata-star whata-star-filled">★</span><span className="whata-star whata-star-filled">★</span><span className="whata-star" style={{ position: 'relative' }}><span className="whata-star-partial" style={{ 'position': 'absolute', overflow: 'hidden', width: '69%' }}>★</span><span className="whata-star-empty">☆</span></span>
                    <span style={{ marginLeft: '5px', color: props.styles.textColor }}>4.7 (61)</span>
                </div><div data-spark="b2c-only" className="grid-product__price"><span>
                    {props.price}
                </span>
                </div>
            </div>
        </a>
        </div>
    </div>
}

export default ReviewGridItemPreview