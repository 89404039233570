import { FormFeedback } from "reactstrap";

import Select from "react-select";
import { Label } from "reactstrap";
import { Permission, PermissionLabels } from "common/security";

const PermissionField = ({ formik, name }: any) => {
    const nValue = formik.values[name]
    return <div className="mb-3">
        <Label htmlFor={name} className="form-label">
            Permisos
        </Label>
        <Select
            isMulti
            name={name}
            placeholder="Seleccione permisos"
            value={nValue ? nValue.map((each: Permission) => ({
                label: PermissionLabels[each as Permission],
                value: each
            })) : null}
            options={Object.keys(Permission).map((each) => ({
                value: each,
                label: PermissionLabels[each as Permission],
            }))}
            onChange={(selectedOptions: any) => {
                formik.setFieldValue(name, selectedOptions.map((each: any) => each.value))
            }}
            className={
                formik.errors[name] &&
                    formik.touched[name]
                    ? 'is-invalid'
                    : ''
            }
            styles={{
                control: (base: any) => ({
                    ...base,
                    borderColor: formik.errors[name] &&
                        formik.touched[name]
                        ? '#dc3545'
                        : base.borderColor
                })
            }}
        />
        {formik.errors[name] && formik.touched[name] ? (
            <FormFeedback type="invalid" className='d-block'>
                {formik.errors[name]}
            </FormFeedback>
        ) : null}
    </div>
}

export default PermissionField