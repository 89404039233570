import React from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

import * as Sentry from '@sentry/react';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// // init firebase backend
// initFirebaseBackend(firebaseConfig);
const FallbackComponent = () => (
  <div>Ups ... ha ocurrido un error, intente luego por favor.</div>
)

function App() {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <React.Fragment>
        <Route />
      </React.Fragment>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);

