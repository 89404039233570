import OrderTable from "Components/Orders/OrderTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap";
import { selectOrder, selectProfile, selectTickets } from "selectors";
import { getTickets } from "slices/thunks";
import { GenericContact } from "types/Dashboard";
import { getOrderList } from "../../slices/order/thunk";
import TicketTable from "./TicketTable";
import useCurrentStore from "Components/Hooks/CurrentStore";
import { Permission, hasPermissions } from "common/security";
import { useNavigate } from "react-router-dom";

interface ContactDetailProps {
    contact: GenericContact | null
    onClose?: () => void
}

const ContactDetail = ({ contact, onClose }: ContactDetailProps) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate()
    const profile = useSelector(selectProfile)
    const [activeTab, setActiveTab] = useState(hasPermissions([Permission.STORE_ORDERS], profile) ? 'orders' : 'tickets')
    const { orderItems } = useSelector(selectOrder)
    const tickets = useSelector(selectTickets)

    useEffect(() => {
        if (profile.currentStoreId && contact) {

            const phones = [contact.phone, contact.billing_phone, contact.shipping_phone].filter(Boolean)

            if (hasPermissions([Permission.STORE_ORDERS], profile)) {
                dispatch(
                    getOrderList({
                        storeId: profile.currentStoreId,
                        phone: phones,
                    })
                )
            }
            if (hasPermissions([Permission.CRM_TICKET], profile)) {
                dispatch(
                    getTickets({
                        storeId: profile.currentStoreId,
                        phone: phones,
                    })
                )
            }
        }
    }, [dispatch, contact?.id, profile.currentStoreId])

    if (!contact) return <></>

    return <Card id="contact-view-detail" data-aos="fade-up">
        {onClose && <CardHeader className="text-end"><Button close={true} onClick={() => onClose()} /></CardHeader>}
        <CardBody className="text-center">
            <h5 className="mt-4 mb-4">{contact.first_name + ' ' + (contact.last_name || '')}</h5>
            <div className="nav">
                <Nav tabs style={{ width: '100%' }} className="justify-content-center mb-3 border-bottom-0 fs-10">
                    {hasPermissions([Permission.STORE_ORDERS], profile) && <NavItem tabId="orders" active={activeTab === 'orders'} style={{ marginRight: '10px' }}>
                        <NavLink className={'nav-link bg-warning-subtle'} style={{ cursor: 'pointer' }} onClick={() => setActiveTab('orders')}> <i className="ri-shopping-cart-line"></i> Pedidos</NavLink>
                    </NavItem>}
                    {hasPermissions([Permission.CRM_TICKET], profile) && <NavItem tabId="tickets" active={activeTab === 'tickets'}>
                        <NavLink className={'nav-link bg-success-subtle'} style={{ cursor: 'pointer' }} onClick={() => setActiveTab('tickets')}> <i className="ri-question-answer-line"></i> Tickets</NavLink>
                    </NavItem>}
                </Nav>
                <TabContent activeTab={activeTab} className="container-fluid p-0">
                    {hasPermissions([Permission.STORE_ORDERS], profile) && <TabPane tabId="orders">
                        <Row>
                            <Col xs={12} className="fs-10">
                                <OrderTable orders={orderItems} fullDetails={false} />
                            </Col>
                        </Row>
                    </TabPane>}
                    {hasPermissions([Permission.CRM_TICKET], profile) && <TabPane tabId="tickets" className="fs-10">
                        <Row>
                            {tickets && <TicketTable
                                tickets={tickets.ticketItems}
                                onSelectTicket={(ticket) => navigate(`/chat/conversations?phone=${encodeURIComponent(ticket.conversation_contact?.phone)}`)} />}
                        </Row>
                    </TabPane>}
                </TabContent>
            </div>
        </CardBody>
        <CardBody>
            <h6 className="text-muted text-uppercase fw-semibold mb-3">
                Detalle de contacto
            </h6>
            <p className="text-muted mb-4">

            </p>
            <div className="table-responsive table-card">
                <Table className="table table-borderless mb-0">
                    <tbody>
                        <tr>
                            <td className="fw-medium">
                                Tipo
                            </td>
                            <td>
                                {contact.type == 'CU' ? <span className="badge bg-primary-subtle text-primary me-1">Cliente</span> : <span className="badge bg-primary-subtle text-primary me-1">Contacto</span>}
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-medium">Creado</td>
                            <td>
                                <small className="text-muted">{new Date(contact.created_at).toLocaleDateString()}</small>
                            </td>
                        </tr>
                        <tr>
                            <td className="fw-medium">
                                Teléfono
                            </td>
                            <td>{contact.phone}</td>
                        </tr>
                        <tr>
                            <td className="fw-medium">
                                Teléfono de envío
                            </td>
                            <td>{contact.shipping_phone}</td>
                        </tr>

                        <tr>
                            <td className="fw-medium">
                                Teléfono de facturación
                            </td>
                            <td>{contact.billing_phone}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </CardBody>
    </Card>
}

export default ContactDetail;