
interface ReviewDetailPreviewProp {
    title: string,
    price: string,
    styles: any,
}

const ReviewDetailPreview = (props: ReviewDetailPreviewProp) => {
    return <div className="reviews-container" style={{
        maxWidth: '1200px',
        fontFamily: props.styles.font ?? '',
    }}>
        {props.styles.summary && <div
            className="reviews-summary"
            style={{
                maxWidth: '100%',
                marginBottom: '3rem',
                padding: '3rem',
                backgroundColor: props.styles.bgColor,
                color: props.styles.textColor,
                borderRadius: '16px',
                boxShadow: props.styles.shadow ? 'rgba(0, 0, 0, 0.08) 0px 4px 20px' : '',
                position: 'relative',
                transition: '0.3s',
            }}
        >
            <h2
                style={{
                    fontSize: props.styles.titleFontSize + 'rem',
                    fontWeight: 700,
                    marginBottom: '1.5rem',
                    color: props.styles.textColor,
                }}
            >
                Resumen de reseñas de compradores
            </h2>
            <p
                style={{
                    fontSize: props.styles.textFontSize + 'rem',
                    lineHeight: '1.6',
                    marginBottom: '2rem',
                }}
            >
                'Vitalité' es un producto destacado por su textura liviana y su capacidad para hidratar instantáneamente, dejando la piel luminosa, fresca, nutrida y tersa. Los usuarios aprecian su humectación duradera, su agradable aroma y el precio accesible. Es recomendado por su efectividad, incluso combinado con CBD o sérum, y su capacidad de dejar la piel sedosa y saludable.
            </p>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '2rem',
                }}
            >
                <div
                    style={{
                        flex: '2 1 0%',
                        minWidth: '300px',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'rgb(0, 123, 255) rgb(240, 240, 240)',
                    }}
                >
                    <img
                        src="https://cdn.burbuxa.com/reviews-media/1fb77e14-1d35-469b-bf24-b030ffe22e3e.jpg"
                        alt="Review image"
                        data-index="0"
                        style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                            marginRight: '1rem',
                            borderRadius: '8px',
                            display: 'inline-block',
                            cursor: 'pointer',
                            transition: 'transform 0.3s',
                            marginTop: '3rem',
                        }}
                    />
                </div>
                <div
                    style={{
                        flex: '1 1 0%',
                        minWidth: '250px',
                    }}
                >
                    <h3
                        style={{
                            fontSize: props.styles.titleFontSize + 'rem',
                            marginBottom: '1rem',
                            color: props.styles.textColor,
                        }}
                    >
                        Calificación Promedio: 4.69
                    </h3>
                    <ul style={{ listStyle: 'none', padding: 0 }}>
                        {[
                            { stars: 5, count: 42 },
                            { stars: 4, count: 19 },
                            { stars: 3, count: 0 },
                            { stars: 2, count: 0 },
                            { stars: 1, count: 0 },
                        ].map((rating) => (
                            <li
                                key={rating.stars}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '0.5rem',
                                    fontSize: props.styles.textFontSize + 'rem',
                                }}
                            >
                                <span
                                    style={{
                                        marginRight: '0.5rem',
                                        color: props.styles.starColor,
                                    }}
                                >
                                    {rating.stars} {'★'.repeat(rating.stars)}
                                </span>
                                <span style={{ marginLeft: 'auto' }}>{rating.count}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: '15px',
                    right: '20px',
                    fontSize: '0.8rem',
                    color: 'rgb(108, 117, 125)',
                    fontWeight: '500',
                }}
            >
                <a
                    href="https://burbuxa.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#6c757d' }}
                >
                    Reviews en WhatsApp by burbuxa.com
                </a>
            </div>
        </div>}
        <div
            className="individual-reviews"
            style={{
                padding: "3rem",
                backgroundColor: props.styles.bgColor,
                color: "#333",
                borderRadius: "16px",
                boxShadow: props.styles.shadow ? 'rgba(0, 0, 0, 0.08) 0px 4px 20px' : '',
                fontFamily: props.styles.font ?? '',
                marginBottom: "2rem",
            }}
        >
            <div
                className="filter-controls"
                style={{
                    marginBottom: "2rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                    alignItems: "center",
                }}
            >
                <select
                    id="sort-select"
                    style={{
                        padding: "0.75rem 1rem",
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        fontSize: "1rem",
                        cursor: "pointer",
                        transition: "0.2s",
                        width: "auto",
                        maxWidth: "250px",
                    }}
                >
                    <option value="newest" selected>
                        Más recientes
                    </option>
                    <option value="oldest">Menos recientes</option>
                </select>
                <select
                    id="rating-select"
                    style={{
                        padding: "0.75rem 1rem",
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        fontSize: "1rem",
                        cursor: "pointer",
                        transition: "0.2s",
                        width: "auto",
                        maxWidth: "250px",
                    }}
                >
                    <option value="all" selected>
                        Todas
                    </option>
                    <option value="5">5 estrellas</option>
                    <option value="4">4 estrellas</option>
                    <option value="3">3 estrellas</option>
                    <option value="2">2 estrellas</option>
                    <option value="1">1 estrella</option>
                </select>
            </div>
            <div
                className="reviews-grid"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "2rem",
                    marginTop: "2rem",
                }}
            >
                <Review
                    name="Mayra"
                    date="18/11/2024"
                    rating={5}
                    starColor={props.styles.starColor}
                    bgColor={props.styles.reviewBgColor}
                    textColor={props.styles.reviewTextColor}
                    titleFontSize={props.styles.titleFontSize}
                    textFontSize={props.styles.textFontSize}
                    text="Me gusta mucho como hidrata la piel y el que tenga vitamina c me encanta, es ligera lo cual es ideal para mi piel mixta, hasta ahora muy buena experiencia"
                />
                <Review
                    name="Marina"
                    date="17/11/2024"
                    rating={5}
                    starColor={props.styles.starColor}
                    bgColor={props.styles.reviewBgColor}
                    textColor={props.styles.reviewTextColor}
                    titleFontSize={props.styles.titleFontSize}
                    textFontSize={props.styles.textFontSize}
                    text="Probe serum y cremas. Todas espectaculares."
                />
                <Review
                    name="Guadalupe Belen"
                    date="16/11/2024"
                    rating={5}
                    starColor={props.styles.starColor}
                    bgColor={props.styles.reviewBgColor}
                    textColor={props.styles.reviewTextColor}
                    titleFontSize={props.styles.titleFontSize}
                    textFontSize={props.styles.textFontSize}
                    text="El mejor tónico q probé y eso q he probado todas las marcas y todos los precios"
                />
                <Review
                    name="Guadalupe"
                    date="15/11/2024"
                    rating={5}
                    starColor={props.styles.starColor}
                    bgColor={props.styles.reviewBgColor}
                    textColor={props.styles.reviewTextColor}
                    titleFontSize={props.styles.titleFontSize}
                    textFontSize={props.styles.textFontSize}
                    text="Muy buena crema, mi piel queda súper suave."
                />
            </div>
        </div>
    </div>
};

const Review = ({ name, date, rating, text, starColor, bgColor, textColor, titleFontSize, textFontSize, }: any) => {
    const stars = Array.from({ length: rating }, (_, i) => (
        <span
            key={i}
            style={{ color: starColor, fontSize: "1.2rem", marginRight: "0.1rem" }}
        >
            ★
        </span>
    ));

    return (
        <div
            className="review"
            style={{
                border: "1px solid rgba(208, 208, 208, 0.6)",
                borderRadius: "12px",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                transition: "0.3s",
                backgroundColor: bgColor,
                transform: "translateY(0px)",
                color: textColor,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <strong style={{ fontSize: textFontSize + 'rem', marginBottom: "0.25rem" }}>
                        {name}
                    </strong>
                    <a
                        href="https://burbuxa.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        <span
                            style={{
                                backgroundColor: "#28a745",
                                color: "white",
                                fontSize: "0.8rem",
                                padding: "2px 5px",
                                borderRadius: "3px",
                            }}
                        >
                            Verificado por burbuxa.com
                        </span>
                    </a>
                </div>
                <div className="stars-container" style={{ display: "inline-flex" }}>
                    {stars}
                </div>
            </div>
            <p
                style={{
                    flexGrow: 1,
                    marginBottom: "1rem",
                    fontSize: textFontSize + 'rem',
                    lineHeight: "1.6",
                }}
            >
                {text}
            </p>
            <small style={{ color: textColor, opacity: 0.7 }}>{date}</small>
        </div>
    );
};

export default ReviewDetailPreview;