export enum Permission {
    REVIEW_CONFIG = "REVIEW_CONFIG",
    REVIEW_VIEW = "REVIEW_VIEW",
    REVIEW_APPROVE = "REVIEW_APPROVE",
    METRICS = "METRICS",
    CAMPAIGN_VIEW = "CAMPAIGN_VIEW",
    CAMPAIGN_CREATE = "CAMPAIGN_CREATE",
    CAMPAIGN_EDIT = "CAMPAIGN_EDIT",
    CAMPAIGN_DELETE = "CAMPAIGN_DELETE",
    TEMPLATE_VIEW = "TEMPLATE_VIEW",
    TEMPLATE_CREATE = "TEMPLATE_CREATE",
    TEMPLATE_DELETE = "TEMPLATE_DELETE",
    STORE_ORDERS = "STORE_ORDERS",
    STORE_SETTINGS = "STORE_SETTINGS",
    CRM_CHAT = "CRM_CHAT",
    CRM_ASSISTANT = "CRM_ASSISTANT",
    CRM_TICKET = "CRM_TICKET",
}

export const PermissionLabels: { [key in Permission]: string } = {
    [Permission.REVIEW_CONFIG]: "Configurar reseñas",
    [Permission.REVIEW_VIEW]: "Ver reseñas",
    [Permission.REVIEW_APPROVE]: "Aprobar reseñas",
    [Permission.METRICS]: "Ver analíticas",
    [Permission.CAMPAIGN_VIEW]: "Ver campañas y automatizaciones",
    [Permission.CAMPAIGN_CREATE]: "Crear campañas y automatizaciones",
    [Permission.CAMPAIGN_EDIT]: "Editar campañas y automatizaciones",
    [Permission.CAMPAIGN_DELETE]: "Eliminar campañas y automatizaciones",
    [Permission.TEMPLATE_VIEW]: "Ver plantillas de mensajes",
    [Permission.TEMPLATE_CREATE]: "Crear plantillas de mensajes",
    [Permission.TEMPLATE_DELETE]: "Eliminar plantillas de mensajes",
    [Permission.STORE_ORDERS]: "Ver listado de pedidos",
    [Permission.STORE_SETTINGS]: "Ver y editar configuración, accesos y facturación",
    [Permission.CRM_CHAT]: "Ver y contestar chats",
    [Permission.CRM_ASSISTANT]: "Ver y editar configuración de Burbujita AI",
    [Permission.CRM_TICKET]: "Ver y editar tickets de soporte"
}

export const isOwner = (state: any) => state.currentStoreRole == 'OWNER'

export const hasPermissions = (permissions: string[], state: any) => (
    isOwner(state) || permissions.some(perm => state.currentStorePermissions.indexOf(perm) !== -1)
)
