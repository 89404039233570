import getChartColorsArray from 'Components/Common/ChartsDynamicColor';
import React, { useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Card, CardHeader } from 'reactstrap';
import { selectCampaigns, selectProfile } from 'selectors';
import { getCampaignDistribution } from 'slices/campaign/thunk';
import { Distribution } from 'types/Dashboard';


interface CampaignDistributionChartProps {
    distribution?: Distribution[]
}

const CampaignDistributionChart = ({ distribution = [] }: CampaignDistributionChartProps) => {
    const series = distribution.map((each) => each.amount || 0);
    const colors = getChartColorsArray('["--vz-primary", "--vz-success", "--vz-secondary", "--vz-info", "--vz-warning"]');

    if (!distribution || distribution.length == 0) {
        return <></>
    }

    var options: any = {
        labels: distribution.map((each) => each.campaign_name),
        chart: {
            height: 333,
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
        colors,
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="donut"
                height="333"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const CampaignConvertionPie = ({ campaignId, fromDate, toDate }: { campaignId: string, fromDate?: any, toDate?: any }) => {
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const campaigns = useSelector(selectCampaigns)

    useEffect(() => {
        if (campaignId && currentStoreId) {
            dispatch(
                getCampaignDistribution({
                    storeId: currentStoreId,
                    campaignId,
                })
            )
        }
    }, [campaignId, currentStoreId])

    if (!campaigns.campaignDistribution || campaigns.campaignDistribution.distribution === undefined || campaigns.campaignDistribution.distribution.length <= 1) {
        return <></>
    }

    return (
        <Card className="card-height-100">
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Conversión</h4>
                <div className="flex-shrink-0">
                </div>
            </CardHeader>

            <div className="card-body">
                {/* <div dir="ltr"> */}
                <CampaignDistributionChart
                    distribution={campaigns?.campaignDistribution?.distribution}
                />
                {/* </div> */}
            </div>
        </Card>
    );
};

export default CampaignConvertionPie;
