import BreadCrumb from 'Components/Common/BreadCrumb'
import useCurrentStore from 'Components/Hooks/CurrentStore'
import ReviewCustomizationForm from 'Components/Reviews/ReviewCustomizationForm'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import { selectProfile } from 'selectors'


const ReviewCustomization = () => {
    useCurrentStore()
    const [values, setValues] = useState<any>({});
    const navigate = useNavigate()
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    document.title = `Personalizar estilos de Reseñas | Burbuxa`

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Personalización de estilos" pageTitle="Reseñas" />
                <ReviewCustomizationForm />
            </Container>
        </div>
    </React.Fragment>
}

export default ReviewCustomization