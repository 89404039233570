import { createSlice } from "@reduxjs/toolkit";
import { generateReviewSelector, getReviewConfig, getReviewList, getReviewTopList, importReviewList, saveReviewConfig, saveReviewStyles, updateReview } from './thunk';


export interface ReviewConfig {
    initial_message_id: number;
    rating_message: number;
    comment_message: number;
    media_message: number;
    thanks_message: number;
    review_delay_days: number;
    review_gap_days: number;
    is_enabled: boolean;
}

export const initialState: any = {
    items: [],
    topItems: [],
    total: 0,
    skip: 0,
    limit: 0,
    config: null as (null | ReviewConfig),
    status: 'loaded' as ('loading' | 'loaded'),
    detailSelector: {},
    selector: {},
};

const ReviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(saveReviewConfig.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
        })
        builder.addCase(saveReviewConfig.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(generateReviewSelector.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(generateReviewSelector.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
            if (action.payload.list) {
                state.selector = action.payload
            } else {
                state.detailSelector = action.payload
            }
        })
        builder.addCase(getReviewTopList.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(getReviewTopList.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
            state.topItems = action.payload
        })
        builder.addCase(updateReview.fulfilled, (state: any, action: any) => {
            const items = [...state.items]
            const item = items.find((each) => each.id === action.payload.id)
            item.is_public = action.payload.is_public
            state.items = items
            state.status = 'loaded'
        })
        builder.addCase(getReviewConfig.fulfilled, (state: any, action: any) => {
            state.config = action.payload
        })
        builder.addCase(getReviewList.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
            state.items = action.payload.items;
            state.total = action.payload.total;
            state.skip = action.payload.skip;
            state.limit = action.payload.limit;
        })
        builder.addCase(importReviewList.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(saveReviewStyles.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(saveReviewStyles.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
        })
        builder.addCase(importReviewList.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
        })
        builder.addCase(importReviewList.rejected, (state: any, action: any) => {
            state.status = 'loaded'
        })
        builder.addCase(getReviewList.pending, (state: any, action: any) => {
            state.status = 'loading'
            state.items = []
            state.total = 0
            state.skip = 0
        })
        builder.addCase(getReviewList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.status = 'loaded'
        })
    }
});

export default ReviewSlice.reducer;