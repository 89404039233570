import Papa from 'papaparse';


interface FileValidatorProps {
    selector: string
    validateRow: (index: number, row: any) => boolean
    onError: (errors: string[]) => void
    onSuccess: (values: string[][]) => void
}

export class FileValidationError extends Error {
}

export const CSVFileValidator = (props: FileValidatorProps) => {
    const validator = () => {
        const el = document.querySelector(props.selector) as HTMLInputElement
        const reader = new FileReader()

        reader.onload = () => {
            const result = reader.result?.toString()
            if (result === undefined) {
                console.log('Empty result')
                return
            }

            Papa.parse(result, {
                header: false,
                skipEmptyLines: true,
                dynamicTyping: true,
                complete: (parsed: any) => {
                    const results = parsed.data
                    const values: string[][] = []
                    const errors: string[] = []
                    let startAtCol = 0;
                    let startAtRow = 0;

                    for (let i = 0; i < results.length; i++) {
                        console.log('*** headers', results[i])
                        const isNotEmpty = results[i].some((value: any) => !!value)
                        if (isNotEmpty) {
                            startAtRow = i + 1
                            break
                        }
                    }
                    console.log('startatrow', startAtRow)
                    for (let i = startAtRow; i < results.length; i++) {
                        try {
                            const row = results[i]
                            if (i === startAtRow) {
                                for (let j = 0; j < row.length; j++) {
                                    if (!!row[j]) {
                                        startAtCol = j
                                        break
                                    }
                                }
                            }
                            const fRow = row.slice(startAtCol, row.length)
                            console.log('***', fRow)
                            props.validateRow(i, fRow)
                            values.push(fRow)
                        } catch (e: any) {
                            errors.push(e.message)
                        }
                    }

                    if (errors.length > 0) {
                        props.onError(errors)
                    }

                    if (values.length > 0) {
                        props.onSuccess(values)
                    }
                },
                error: (err: any) => {
                    console.error(err);
                },
            });

        }

        if (el.files && el.files.length > 0) {
            reader.readAsBinaryString(el.files[0])
        }
    }
    return validator
}

export function isDateValid(dateStr: string) {
    const date = new Date(dateStr);
    return !isNaN(date.getTime())
}