import { Alert, Button, Col, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import ReviewGridItemPreview from "./ReviewGridItemPreview"
import CustomGridItemForm from "./CustomGridItemForm"
import { useCallback, useEffect, useState } from "react"
import CustomDetailForm from "./CustomDetailForm"
import ReviewDetailPreview from "./ReviewDetailPreview"
import { useSelector } from "react-redux"
import { selectProfile, selectReviews } from "selectors"
import { useDispatch } from "react-redux"
import { getReviewConfig, saveReviewStyles } from "slices/reviews/thunk"
import useCurrentStore from "Components/Hooks/CurrentStore"
import { Link } from "react-router-dom"


interface ReviewCustomizationFormProp {

}


const ReviewCustomizationForm = (props: ReviewCustomizationFormProp) => {
    const reviews = useSelector(selectReviews)
    const profile = useSelector(selectProfile)
    const dispatch: any = useDispatch()
    const [styles, setStyles] = useState({
        fontSize: '1',
        marginTop: 0,
        marginBottom: 0,
        parentSelector: '',
        adjacentSelector: '',
    })
    const [detailStyles, setDetailStyles] = useState({
        titleFontSize: 1.2,
        textFontSize: 1,
        marginTop: 0,
        marginBottom: 0,
        font: null,
        bgColor: '#fff',
        shadow: true,
        reviewBgColor: '#fff',
        reviewTextColor: '#000',
        summary: true,
        parentSelector: '',
        adjacentSelector: '',
    })
    const [activeTab, setActiveTab] = useState('1')

    useEffect(() => {
        if (profile.currentStoreId) {
            dispatch(
                getReviewConfig({
                    storeId: profile.currentStoreId,
                })
            )
        }
    }, [profile.currentStoreId])

    useEffect(() => {
        if (reviews.config && reviews.config.list_style_config) {
            setStyles(reviews.config.list_style_config);
        }

        if (reviews.config && reviews.config.detail_style_config) {
            setDetailStyles(reviews.config.detail_style_config);
        }
    }, [reviews.config])

    const onSave = useCallback(() => {
        if (profile.currentStoreId) {
            dispatch(
                saveReviewStyles({
                    storeId: profile.currentStoreId,
                    config: {
                        detail: detailStyles,
                        list: styles,
                    }
                })
            )
        }
    }, [profile.currentStoreId, detailStyles, styles])

    if (reviews.config === null || reviews.config.is_enabled === undefined) {
        return <Row>
            <Col sm={12}>
                <Alert color="warning">
                    Primero debes habilitar las reseñas y configurar los mensajes, haz clic <Link to={`/reviews/config?store=${profile.currentStoreId}`}>aqui</Link> para proceder.
                </Alert>
            </Col>
        </Row>
    }

    return <Row>
        <style>{`
            .whata-star-container {
                flex-direction: row;
                gap: 5px;
                align-items: center;
                margin-top: 1rem;
                display: block !important;
                visibility: visible !important;
                z-index: 9999 !important;
                justify-content: center;
                display: flex;
            }
            .whata-star-filled {
                color: gold;
            }
            .whata-star-empty {
                color: #ccc;
            }
            .whata-star-partial {
                color: gold;
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;
            }
            .whata-star {
                position: relative;
                display: inline-block;
                font-size: 20px;
                line-height: 1;
            }
        `}
        </style>
        <Col xs={12}>
            <Nav tabs className="nav-tabs-custom rounded card-header-tabs border-bottom-0 nav">
                <NavItem>
                    <NavLink
                        active={activeTab == '1'}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => setActiveTab('1')}
                    >Listado de productos</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={activeTab == '2'}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => setActiveTab('2')}
                    >Detalle del producto</NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} style={{ marginTop: '10px' }}>
                <TabPane tabId="1">
                    <Row>
                        <Col xs={3}>
                            <CustomGridItemForm
                                styles={styles}
                                setStyles={(values) => setStyles({
                                    ...styles,
                                    ...values,
                                })}
                                setStyle={(k, v) => setStyles({
                                    ...styles,
                                    [k]: v
                                })}
                            />
                        </Col>
                        <Col xs={9}>
                            <div className="d-flex gap-5 align-item-center">
                                <ReviewGridItemPreview
                                    title="Vitalité 1"
                                    price="$55.000,00"
                                    styles={styles}
                                />
                                <ReviewGridItemPreview
                                    title="Vitalité 2"
                                    price="$55.000,00"
                                    styles={styles}
                                />
                            </div>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col xs={3}>
                            <CustomDetailForm
                                styles={detailStyles}
                                setStyle={(k, v) => setDetailStyles({
                                    ...detailStyles,
                                    [k]: v
                                })}
                            />
                        </Col>
                        <Col xs={9}>
                            <div className="d-flex gap-5 align-item-center">
                                <ReviewDetailPreview
                                    title="Vitalité 1"
                                    price="$55.000,00"
                                    styles={detailStyles}
                                />
                            </div>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
            <div className="d-flex mb-4 justify-content-end card card-body">
                <Button
                    onClick={() => onSave()}
                    disabled={reviews.status === 'loading'}
                    color="success"
                >{reviews.status === 'loading' ? 'Guardando...' : 'Publicar cambios'}</Button>
            </div>
        </Col>
    </Row>

}

export default ReviewCustomizationForm