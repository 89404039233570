import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//import images
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import { useDispatch, useSelector } from 'react-redux';
import { selectAlerts, selectProfile } from 'selectors';
import { getAlerts, markAsReadAlert } from 'slices/alerts/thunk';
import AlertItem from './AlertItem';
import { Alert } from 'types/Dashboard';


const ALERT_INTERVAL = 10000

const AlertDropdown = () => {
    const profile = useSelector(selectProfile)
    const dispatch: any = useDispatch()
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const alerts = useSelector(selectAlerts)

    useEffect(() => {
        if (profile.currentStoreId) {
            const _getAllNotifications = () => {
                dispatch(
                    getAlerts({
                        storeId: profile.currentStoreId
                    })
                ).then(() => {
                    timeoutRef.current = setTimeout(() => _getAllNotifications(), ALERT_INTERVAL)
                })
            }

            timeoutRef.current = setTimeout(() => _getAllNotifications(), ALERT_INTERVAL)
        }

        return () => {
            if (timeoutRef.current) {
                console.log('Removing timeout alert', timeoutRef.current)
                window.clearTimeout(timeoutRef.current)
            }
        }
    }, [profile.currentStoreId])
    const [isNotificationDropdown, setIsNotificationDropdown] = useState<boolean>(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const onDismiss = useCallback((alertId: any) => {
        if (profile.currentStoreId) {
            dispatch(
                markAsReadAlert({ storeId: profile.currentStoreId, alertId })
            ).then(() => dispatch(
                getAlerts({
                    storeId: profile.currentStoreId
                })
            ))
        }
    }, [profile.currentStoreId])

    if (alerts.items === undefined) {
        return <></>
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    {alerts.items.length > 0 && <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{alerts.items.length}<span
                            className="visually-hidden">alertas</span></span>}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" style={{ overflowX: 'hidden' }}>
                    {alerts.items.length > 0 && <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Alertas</h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge bg-light-subtle text-body fs-13"> {alerts.items.length} alerta{alerts.items.length > 1 ? 's' : ''}</span>
                                </div>
                            </Row>
                        </div>
                    </div>}
                    {alerts.items.map((each: Alert) => (
                        <React.Fragment key={each.id}>
                            <AlertItem
                                alert={each}
                                onDismiss={() => onDismiss(each.id)}
                            />
                        </React.Fragment>
                    ))}

                    {alerts.items.length == 0 && <>
                        <div className="w-25 w-sm-50 pt-3 mx-auto">
                            <img src={bell} className="img-fluid" alt="user-pic" />
                        </div>
                        <div className="text-center pb-5 mt-2">
                            <h6 className="fs-18 fw-semibold lh-base">No hay alertas nuevas</h6>
                        </div>
                    </>}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default AlertDropdown;