

import moment from 'moment-timezone'
import 'moment/locale/es'
import React from 'react'
import { Button } from 'reactstrap'
import { Alert } from 'types/Dashboard'

moment.locale('es')


interface AlertItemProps {
    alert: Alert
    onDismiss: () => void
}

const AlertItem = ({ alert, onDismiss }: AlertItemProps) => {
    return <div className="text-reset notification-item d-block dropdown-item alert-item" data-alert={alert.id}>
        <div className="d-flex align-items-center">
            {alert.severity == 'SUCCESS' && <span style={{ minWidth: '30px', minHeight: '30px' }} className='me-3 rounded-circle avatar-xs bg-success d-flex justify-content-center align-items-center text-white'>
                <span className='mdi mdi-check fs-bold'></span>
            </span>}
            {alert.severity == 'ERROR' && <span style={{ minWidth: '30px', minHeight: '30px' }} className='me-3 rounded-circle avatar-xs bg-danger d-flex justify-content-center align-items-center text-white'>
                <span className='mdi mdi-close fs-bold'></span>
            </span>}
            {alert.severity == 'INFO' && <span style={{ minWidth: '30px', minHeight: '30px' }} className='me-3 rounded-circle avatar-xs bg-info d-flex justify-content-center align-items-center text-white'>
                <span className='mdi mdi-information-variant fs-bold'></span>
            </span>}
            <div className="flex-grow-1">
                <div className="text-muted" style={{ fontSize: '10px' }}>
                    <p className="mb-1">{alert.message}</p>
                </div>
                <p style={{ fontSize: '8px' }} className="mb-0 fw-medium text-uppercase text-muted">
                    <span><i className="mdi mdi-clock-outline"></i>{' '}
                        {moment(new Date(alert.created_at)).startOf('minute').fromNow()}
                    </span>
                </p>
            </div>
            <div className="px-2 fs-10">
                <div className="form-check notification-check">
                    <Button color='primary' size='sm' onClick={() => onDismiss()}>OK</Button>
                </div>
                {/* <input className="form-check-input" type="checkbox" /> */}
            </div>
        </div>
    </div>
}

export default AlertItem