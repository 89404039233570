import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { selectProfile, selectReviews } from "selectors"
import { generateReviewSelector } from "slices/reviews/thunk"


interface CustomDetailFormProps {
    setStyle: (key: any, value: any) => void,
    styles: any,
}

const CustomDetailForm = (props: CustomDetailFormProps) => {
    const profile = useSelector(selectProfile)
    const reviews = useSelector(selectReviews)
    const dispatch: any = useDispatch()

    useEffect(() => {
        if (reviews.detailSelector && reviews.detailSelector.expression) {
            props.setStyle('parentSelector', reviews.detailSelector.expression)
        }
    }, [reviews.detailSelector])

    const onGenerateClick = useCallback((url: string) => {
        dispatch(
            generateReviewSelector({
                storeId: profile.currentStoreId,
                list: false,
                url,
            })
        )

    }, [profile.currentStoreId])

    return <Card>
        <CardHeader>
            Estilos del detalle
        </CardHeader>
        <CardBody>
            <Form>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            URL de ejemplo de detalle
                        </Label>
                        <InputGroup>
                            <Input type="text"
                                onChange={(e) => props.setStyle("detailUrl", e.target.value)}
                                value={props.styles.detailUrl}
                            />
                            <Button
                                disabled={reviews.status === 'loading'}
                                onClick={(e) => onGenerateClick(props.styles.detailUrl)}>
                                <i className="ri-search-line"></i>
                            </Button>
                        </InputGroup>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            Selector de contenedor
                        </Label>
                        <Input type="text" onChange={(e) => props.setStyle("parentSelector", e.target.value)} value={props.styles.parentSelector} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            Color de estrella
                        </Label>
                        <Input type="color" onChange={(e) => props.setStyle("starColor", e.target.value)} value={props.styles.starColor} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            Color de texto
                        </Label>
                        <Input type="color" onChange={(e) => props.setStyle("textColor", e.target.value)} value={props.styles.textColor} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            Color de fondo
                        </Label>
                        <Input type="color" onChange={(e) => props.setStyle("bgColor", e.target.value)} value={props.styles.bgColor} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            Fondo de caja de comentario
                        </Label>
                        <Input type="color" onChange={(e) => props.setStyle("reviewBgColor", e.target.value)} value={props.styles.reviewBgColor} />
                    </FormGroup>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="star_color">
                                Tipografía
                            </Label>
                            <Input type="text" onChange={(e) => props.setStyle("font", e.target.value)} placeholder="Default" value={props.styles.font} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="star_color">
                                Tamaño título
                            </Label>
                            <Input type="number" step={'0.1'} onChange={(e) => props.setStyle("titleFontSize", e.target.value)} placeholder="Default" value={props.styles.titleFontSize} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="star_color">
                                Tamaño texto
                            </Label>
                            <Input type="number" step={'0.1'} onChange={(e) => props.setStyle("textFontSize", e.target.value)} placeholder="Default" value={props.styles.textFontSize} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            Color de texto de review
                        </Label>
                        <Input type="color" onChange={(e) => props.setStyle("reviewTextColor", e.target.value)} value={props.styles.reviewTextColor} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup check>
                        <Label for="summary" check>
                            Mostrar resumen?
                        </Label>
                        <Input
                            id="summary"
                            type="checkbox"
                            onChange={(e) => props.setStyle("summary", e.target.checked)}
                            checked={props.styles.summary} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup check>
                        <Label for="shadow" check>
                            Mostrar sombra?
                        </Label>
                        <Input
                            id="shadow"
                            type="checkbox"
                            onChange={(e) => props.setStyle("shadow", e.target.checked)}
                            checked={props.styles.shadow} />
                    </FormGroup>
                </Row>
            </Form>
        </CardBody>
    </Card>
}

export default CustomDetailForm 