import { createSlice } from "@reduxjs/toolkit";
import { getAlerts, markAsReadAlert } from './thunk';
import { Alert, GenericContact } from "types/Dashboard";

export const initialState: any = {
    items: [] as Alert[],
    status: 'loaded' as ('loading' | 'loaded' | 'error'),
    error: {},
};

const alertSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAlerts.pending, (state: any, action: any) => {
            state.status = 'loading'
        })

        builder.addCase(getAlerts.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
            state.items = action.payload.items
        })
        builder.addCase(markAsReadAlert.pending, (state: any, action: any) => {
            state.status = 'loading'
        })

        builder.addCase(markAsReadAlert.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
        })
    },
});

export default alertSlice.reducer;