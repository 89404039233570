import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getGroups, createGroup, deleteGroup } from './thunk';

export const initialState: any = {
    status: 'loaded' as ('loading' | 'loaded' | 'created' | 'deleted'),
    groupItems: [],
    redirToList: false,
    showGroupModal: false,
    currentGroup: null,
};

const GroupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        showGroupModal(state, action: PayloadAction<boolean>) {
            state.showGroupModal = action.payload
        },
        selectCurrentGroup(state, action: PayloadAction<any>) {
            state.currentGroup = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGroups.fulfilled, (state: any, action: any) => {
            const data = action.payload;
            state.status = 'loaded'
            state.groupItems = data
        })
        builder.addCase(getGroups.rejected, (state: any, action: any) => {
            state.status = 'loaded'
            state.groupItems = null
        })
        builder.addCase(createGroup.fulfilled, (state: any, action: any) => {
            state.status = 'created'
            state.showGroupModal = false
        })
        builder.addCase(getGroups.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(createGroup.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(deleteGroup.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(deleteGroup.fulfilled, (state: any, action: any) => {
            state.status = 'deleted'
        })
    }
});

export const { selectCurrentGroup, showGroupModal } = GroupSlice.actions;

export default GroupSlice.reducer;