import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { apiGetAlerts, apiUpdateAlert } from "../../helpers/backend_helper";

export const getAlerts = createAsyncThunk("alerts/getAlerts", async ({ storeId }: any) => {
    try {
        const response = await apiGetAlerts({ storeId })
        return response;
    } catch (error) {
        return error;
    }
})

export const markAsReadAlert = createAsyncThunk("alerts/markAsReadAlert", async ({ storeId, alertId }: any) => {
    try {
        const response = await apiUpdateAlert({ storeId, alertId })
        return response;
    } catch (error) {
        return error;
    }
})