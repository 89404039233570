import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { selectProfile, selectReviews } from "selectors"
import { generateReviewSelector } from "slices/reviews/thunk"


interface CustomGridItemFormProps {
    setStyle: (key: any, value: any) => void,
    setStyles: (values: any) => void,
    styles: any,
}

const CustomGridItemForm = (props: CustomGridItemFormProps) => {
    const profile = useSelector(selectProfile)
    const reviews = useSelector(selectReviews)
    const dispatch: any = useDispatch()

    useEffect(() => {
        if (reviews.selector && reviews.selector.expression) {
            props.setStyles({
                parentSelector: reviews.selector.expression,
                adjacentSelector: reviews.selector.price,
            })
        }
    }, [reviews.selector])

    const onGenerateClick = useCallback((url: string) => {
        dispatch(
            generateReviewSelector({
                storeId: profile.currentStoreId,
                list: true,
                url,
            })
        )

    }, [profile.currentStoreId])

    return <Card>
        <CardHeader>
            Estilos de listado
        </CardHeader>
        <CardBody>
            <Form>
                <Row>
                    <FormGroup>
                        <Label for="star_color">
                            URL de ejemplo de listado
                        </Label>
                        <InputGroup>
                            <Input type="text"
                                onChange={(e) => props.setStyle("url", e.target.value)}
                                value={props.styles.url}
                            />
                            <Button
                                disabled={reviews.status === 'loading'}
                                onClick={(e) => onGenerateClick(props.styles.url)}>
                                <i className="ri-search-line"></i>
                            </Button>
                        </InputGroup>
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label>
                            Selector de elemento padre
                        </Label>
                        <Input type="text" onChange={(e) => props.setStyle("parentSelector", e.target.value)} value={props.styles.parentSelector} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label>
                            Selector de elemento adyacente
                        </Label>
                        <Input type="text" onChange={(e) => props.setStyle("adjacentSelector", e.target.value)} value={props.styles.adjacentSelector} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label>
                            Atributo de ID
                        </Label>
                        <Input type="text" onChange={(e) => props.setStyle("productId", e.target.value)} value={props.styles.productId} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label>
                            Color de estrella
                        </Label>
                        <Input type="color" onChange={(e) => props.setStyle("starColor", e.target.value)} value={props.styles.starColor} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                        <Label>
                            Color de texto
                        </Label>
                        <Input type="color" onChange={(e) => props.setStyle("textColor", e.target.value)} value={props.styles.textColor} />
                    </FormGroup>
                </Row>
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <Label>
                                Márgen superior
                            </Label>
                            <Input type="number" step='0.1' onChange={(e) => props.setStyle("marginTop", e.target.value)} value={props.styles.marginTop} />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Label>
                                Márgen inferior
                            </Label>
                            <Input type="number" step='0.1' onChange={(e) => props.setStyle("marginBottom", e.target.value)} value={props.styles.marginBottom} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                Tipografía
                            </Label>
                            <Input type="text" onChange={(e) => props.setStyle("font", e.target.value)} placeholder="Default" value={props.styles.font} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>
                                Tamaño tipografía
                            </Label>
                            <Input type="number" step='0.1' onChange={(e) => props.setStyle("fontSize", e.target.value)} placeholder="Default" value={props.styles.fontSize} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <FormGroup check>
                        <Label for="bold" check>
                            Negrita?
                        </Label>
                        <Input
                            id="bold"
                            type="checkbox"
                            onChange={(e) => props.setStyle("bold", e.target.checked)}
                            checked={props.styles.bold} />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup s>
                        <Label>
                            Alineación
                        </Label>
                        <Input type="select" onChange={(e) => props.setStyle("align", e.target.value)} value={props.styles.align}>
                            <option value="">---</option>
                            <option value="left">Izquierda</option>
                            <option value="center">Centrado</option>
                            <option value="right">Derecha</option>
                        </Input>
                    </FormGroup>
                </Row>
            </Form>
        </CardBody>
    </Card>
}

export default CustomGridItemForm 