
import TicketTable from "Components/CRM/TicketTable";
import { Ticket } from "types/Dashboard";
import Pagination from "Components/Common/Pagination";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectProfile, selectTickets } from "selectors";
import { getTickets, updateTicket } from "slices/tickets/thunk";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import useCurrentStore from "Components/Hooks/CurrentStore";

const TicketList = () => {
    useCurrentStore()
    const tickets = useSelector(selectTickets);
    const dispatch: any = useDispatch();
    const { currentStoreId } = useSelector(selectProfile);
    const navigate = useNavigate();

    const fetchTickets = (skip: number) => {
        dispatch(
            getTickets({ storeId: currentStoreId, skip, status: 'ARCHIVED' })
        );
    }

    useEffect(() => {
        if (currentStoreId) {
            fetchTickets(0);
        }
    }, [currentStoreId]);

    const onUpdateTicket = (ticket: Ticket, status: string) => {
        dispatch(
            updateTicket({ storeId: currentStoreId, ticketId: ticket.id, data: { status } })
        )
    }

    return <div className="page-content">
        <div className="container-fluid">
            <BreadCrumb pageTitle="Tickets archivados" title="Tickets" />
            <div className="row">
                <div className="col-12">
                    <div className="card p-0 m-0">

                        <TicketTable
                            tickets={tickets?.ticketItems || []}
                            onSelectTicket={(ticket: Ticket) => {
                                navigate(`/chat/conversations?phone=${encodeURIComponent(ticket.conversation_contact?.phone)}`)
                            }}
                            onUpdateTicket={onUpdateTicket}
                        />
                        <Pagination
                            total={tickets.limit !== 0 ? Math.ceil(tickets.total / tickets.limit) : 0}
                            data={tickets?.ticketItems || []}
                            currentPage={tickets.limit !== 0 ? Math.ceil(tickets.skip / tickets.limit) + 1 : 0}
                            setCurrentPage={(page: any) => fetchTickets((page - 1) * tickets.limit)}
                            perPageData={tickets.limit}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default TicketList;